<template>
    <mnSup mostrar="true" />
    <main id="pol-priv">
        <div class="texto">
            <h2>POLÍTICAS DE PRIVACIDAD</h2><br/><br/>
            <p>
                La presente Política de Privacidad tiene por finalidad informar a los usuarios del tratamiento de los datos personales que se recogen a través de <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a>, en cumplimiento con la Ley de protección de datos personales.
            </p><br/>
            <h3>RESPONSABILIDAD DEL MINISTERIO DE CIENCIA, TECNOLOGÍA, CONOCIMIENTO E INNOVACIÓN POR USO DE DATOS.</h3><br/>
            <p>
                El Ministerio de Ciencia, Tecnología, Conocimiento e Innovación adoptará las medidas de seguridad necesarias para garantizar la confidencialidad de los datos recogidos. El Ministerio de Ciencia, Tecnología, Conocimiento e Innovación y los funcionarios que intervengan en cualquier fase del tratamiento de los datos de carácter personal están obligados al secreto profesional respecto de los mismos y al deber de guardarlos.
            </p>
            <p>
                Los datos entregados por los usuarios de<a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a> serán administrados exclusivamente por personal del Minciencia, evitando usos indebidos, alteración o entrega a terceros. Sin perjuicio de lo anterior, el Ministerio de Ciencia, Tecnología, Conocimiento e Innovación no se hará responsable del uso que puedan dar terceras personas a los datos personales entregados por sus titulares en foros o espacios abiertos al público.
            </p>
            <p>
                En el caso que, mediante contrato de servicio, sea una entidad externa o empresa quien administre las bases de datos que se recogen a través de <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a>, el Ministerio suscribirá un acuerdo de confidencialidad, de manera que bajo ninguna circunstancia la empresa o entidad pueda comercializar, utilizar ni entregar datos personales de los usuarios a terceros.
            </p><br/>
            <h3>FORMA DE RECOPILACION DE DATOS.</h3><br/>
            <p><strong>Automáticos</strong></p>
            <p>Procesos electrónicos que permiten registrar actividades y comportamientos generales de los usuarios dentro de un sitio web, pero no permiten su identificación personal. Ejemplos de esto son: cantidad de visitantes al web, horarios de ingreso, páginas más vistas, cantidad de páginas vistas</p>
            <p><strong>Manuales (Formularios, Foros):</strong></p>
            <p>Solicitudes directas de información a los visitantes o usuarios del portal a través de la(s) cual(es) se recolectan datos tales como, nombre, rut, correo electrónico, ocupación u otros datos personales.</p>
            <p>Los datos personales de los usuarios serán utilizados para el cumplimiento de los fines indicados en el (los) formulario (s) correspondiente (s) y siempre dentro de las competencias y atribuciones del Ministerio de Ciencia, Tecnología, Conocimiento e Innovación. En ellos se informará sobre las específicas finalidades para los que se recogen los datos.</p>
            <p>Conforme a lo dispuesto en el artículo 19 Nº 4 de la Constitución Política de la República y a las normas pertinentes de la Ley Nº 19.628 sobre protección de la vida privada y sus modificaciones posteriores, los datos personales que los titulares revelen en su participación en foros alojados en la página del</p>
            <p>Ministerio de Ciencia, Tecnología, Conocimiento e Innovación serán almacenados junto al resto de contenidos, con el fin de permitir el funcionamiento y mantenimiento del foro y las contribuciones en él realizadas. En ningún caso esos datos serán tratados por el Ministerio o sus funcionarios con fines diferentes, sin solicitar el previo consentimiento de su titular.</p>
            <p><strong>Uso de la información</strong></p>
            <p>Los datos personales recogidos a través de <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a> serán almacenados únicamente durante el tiempo necesario para garantizar la prestación del servicio o el cumplimiento de la finalidad para la que fueron recabados.</p>
            <p><strong>Utilización con fines estadísticos</strong></p>
            <p>Los datos recogidos a través de la página web podrán ser conservados y tratados con fines estadísticos, siempre y cuando sea imposible la identificación de sus titulares.
            </p><br/>
            <h3>ENTREGA DE INFORMACIÓN A TERCEROS.</h3><br/>
            <p>El Ministerio de Ciencia, Tecnología, Conocimiento e Innovación podrá utilizar la información recopilada automáticamente y que no contiene identificación personal de los/as usuarios/as, con el fin de informar a terceros, como otras entidades gubernamentales y organismos, sobre temas relativos a audiencia, número de visitas y áreas más visitadas en <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a>.</p>
            <p>El Ministerio puede revelar los datos personales si es requerido a hacerlo por mandato legal o por una orden judicial.</p>
            <p>Asimismo, puede revelar los datos personales si los/as usuarios/as infringen las condiciones generales de uso de este sitio que regulan el acceso y uso de los servicios y materiales ofrecidos, y en aquellos casos en que sea imprescindible para defender los derechos del Ministerio de Ciencia, Tecnología, Conocimiento e Innovación, de otros usuarios/as o de terceros.</p><br/>
            <h3>DERECHOS USUARIOS</h3><br/>
            <p>El Usuario podrá en todo momento ejercer los derechos otorgados por la ley Nº 19.628 sobre protección de la vida privada y sus modificaciones posteriores. En específico, podrá:</p>
            <ul>
                <li>Solicitar información respecto de los bancos de datos de que sea responsable el organismo, el fundamento jurídico de su existencia, su finalidad, tipos de datos almacenados y descripción del universo de personas que comprende;</li>
                <li>Solicitar información sobre los datos relativos a su persona, su procedencia y destinatario, el propósito del almacenamiento y la individualización de las personas u organismos a los cuales sus datos son transmitidos regularmente;</li>
                <li>Solicitar se modifiquen sus datos personales cuando ellos no sean correctos o no estén actualizados, si fuere procedente;</li>
                <li>Solicitar la eliminación o cancelación de los datos entregados cuando así lo desee, en tanto fuere procedente;</li>
                <li>Solicitar, de conformidad con lo dispuesto en la Ley 19.628, copia del registro alterado en la parte pertinente, si fuere del caso; y,</li>
                <li>Oponerse a que sus datos personales sean utilizados para fines estadísticos</li>
            </ul>
            <p>Para ejercer sus derechos el Usuario podrá dirigirse al Servicio de Contáctenos indicando claramente su solicitud.</p><br/>
            <h3>TITULARIDAD DE DERECHOS</h3><br/>
            <p>Pertenecen al Minciencia todos los derechos, incluidos los de propiedad intelectual, respecto de los contenidos, formularios o publicaciones de cualquier índole que aparezcan en <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a>  que hayan sido realizadas por funcionarios de esta Cartera (y que de acuerdo con la ley se encuentren en el dominio público). Estos contenidos pueden ser utilizados por el usuario para fines no comerciales. Para su utilización comercial será necesaria la autorización por escrito de Ministerio de Ciencia, Tecnología, Conocimiento e Innovación, conforme lo que señale el reglamento de licenciamiento de dichos contenidos.</p>
            <p>Para efectos de la presente “Política de Privacidad”, se entiende por Usuario a la persona que voluntariamente se registre en www.minciencia.gob.cl en los formularios especialmente establecidos al efecto. En tanto, Visitante es aquella persona que accede libremente a la información disponible en <a href="www.minciencia.gob.cl" target="_blank"> www.minciencia.gob.cl</a>  sin necesidad de registrarse previamente.</p>
            <p>Nota: La presente Política de Privacidad de Datos Personales no suscribe un compromiso contractual entre los usuarios y el Ministerio de Ciencia, Tecnología, Conocimiento e Innovación, sino que cumple un fin netamente informativo.

</p>
        </div>
    </main>
    <footerLogo/>
</template>
<script>
import footerLogo from '@/components/footerLogo.vue'
import mnSup from '@/components/mnSup.vue'

export default{
    name: 'Politicas',
    data(){
        return{

        }
    },
    components:{
        mnSup,
        footerLogo
    },
    created(){
        document.title= 'XXI Congreso Nacional Explora'
    }
}
</script>
<style lang="scss" scoped >
main#pol-priv{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 100%;
    @media(max-width:700px){
        display:block;
    }
    .texto{
        max-width: 80%;
        display: grid;
        grid-template-columns: 100%;
        margin: 50px;
        margin-top:100px;
        h2{
            font-weight: 700;
        }
    }
}

</style>